import React from 'react';
import { useTranslation } from 'react-i18next';

import './workWe.css';

const WorkWe = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section-5">
        <div className="primary flex col center">
          <h2>{t('workWe.title')}</h2>

          <span className="m-8 text-center">
            {t('workWe.desc')}
          </span>

          <a className="btn" href="/cv">{t('workWe.button')}</a>
        </div>
      </section>
    </>
  );
}
export default WorkWe;
