import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout';
import Walls from '../components/common/walls';

import icon1 from '../images/icon_1.png';
import icon2 from '../images/icon_2.png';
import icon3 from '../images/icon_3.png';
import aboutUs from '../images/about-us.png';
import youThink from '../images/tu-idea.png';
import joseIsidroImg from '../images/jose_isidro.png';

import './home.css';
import WorkWe from '../components/common/workWe';

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="banner">
        <div className="banner-div">
          <h1>{t('home.bannerTitle')}</h1>

          <span>{t('home.bannerSpan')}</span>

          <a className="btn btn-light" href="/contacto">{t('home.bannerButton')}</a>
        </div>
      </div>

      <div className="container">
        <section className="section-1 flex col center">
          <span className="what-do-we-do-span">{t('home.whatDoWedo')}</span>

          <h2 className="text-center m-v-16">
            {t('home.subTitle')}
          </h2>

          <div className="row cards">
            <div className="col-3 card card-min-300">
              <img alt="social media" className="icon-circle" src={icon1} />

              <div className="card-body">
                <h5>{t('home.socialTitle')}</h5>

                <p className="card-text">{t('home.socualDesc')}</p>
              </div>
            </div>

            <div className="col-3 card card-min-300">
              <img alt="social media" className="icon-circle" src={icon2} />

              <div className="card-body">
                <h5>{t('home.webTitle')}</h5>

                <p className="card-text">{t('home.webDesc')}</p>
              </div>
            </div>

            <div className="col-3 card card-min-300">
              <img alt="social media" className="icon-circle" src={icon3} />

              <div className="card-body">
                <h5>{t('home.webpAppTitle')}</h5>

                <p className="card-text">{t('home.webAppDesc')}</p>
              </div>
            </div>
          </div>

          <div className="line" />
        </section>

        <section className="section-2">
          <div id="nosotros" className="col about-us">
            <span className="text-center">{t('home.aboutUs')}</span>

            <div className="row">
              <div className="col center">
                <img alt="nostros ebox code" src={aboutUs} />
              </div>

              <div className="about-us-sub-text col flex-2 aling-items-center">
                <h2>{t('home.aboutUsSubTitle')}</h2>

                <p>{t('home.aboutUsDesc')}</p>

                <a href="/nosotros" className="btn btn-black">{t('home.aboutUsButton')}</a>
              </div>
            </div>
          </div>
        </section>

        <section id="servicios" className="section-3 flex col center">
          <span className="text-center">{t('home.servicesUs')}</span>

          <h2 className="text-center">{t('home.serbicesUsSubTitle')}</h2>

          <img alt="tu idea" src={youThink} />

          <div className="row tegnologies">
            <div className="col-3 ">
              <h5>{t('home.mobileApp')}</h5>

              <ul className="col">
                <li className="p-v-8">+ iOS</li>
                <li className="p-v-8">+ Android</li>
                <li className="p-v-8">+ React Native</li>
              </ul>
            </div>

            <div className="col-3">
              <h5>{t('home.uiUx')}</h5>

              <ul className="col">
                <li className="p-v-8">{t('home.ui-1')}</li>
                <li className="p-v-8">{t('home.ui-2')}</li>
                <li className="p-v-8">{t('home.ui-3')}</li>
                <li className="p-v-8">{t('home.ui-4')}</li>
              </ul>
            </div>

            <div className="col-3">
              <h5>{t('home.webDevelop')}</h5>

              <ul className="col">
                <li className="p-v-8">+ Angular</li>
                <li className="p-v-8">+ Javascript</li>
                <li className="p-v-8">+ HTML</li>
                <li className="p-v-8">{`+ React Js ${t('home.webDevelopMore')}`}</li>
              </ul>
            </div>
          </div>
        </section>

        <div className="flex center p-v-8">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            className="video"
            src="https://www.youtube.com/embed/BNEEmkc8X3U"
            title="YouTube video player"
            width="100%"
          />
        </div>

        <section className="section-4">
          <div className="row">
            <div className="col ">
              <p>{t('home.section4')}</p>
              <div>
                <span>
                  <strong>José Isidro Burgos</strong>
                  {' '}
                  CEO
                </span>
              </div>
            </div>

            <div className="col">
              <img alt="José Isidro Burgos CEO" src={joseIsidroImg} />
            </div>
          </div>
        </section>

        <Walls
          src="https://walls.io/js/wallsio-widget-1.2.js"
          wallurl="https://my.walls.io/e6n7z?nobackground=1&amp;show_header=0"
          title="My social wall"
          width="80%"
          height="300"
        />

        <WorkWe />
      </div>
    </Layout>
  );
};

export default IndexPage;
