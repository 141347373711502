import React, { useEffect } from 'react';

const Walls = ({ async = true, autoheight = "1", height, lazyload = "1", src, title, wallurl, width }) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'wall-io';
    script.src = src;
    script.async = true;
    script.setAttribute('data-wallurl', wallurl);
    script.setAttribute('data-tile', title);
    script.setAttribute('data-width', width);
    script.setAttribute('data-autoheight', autoheight);
    script.setAttribute('data-height', height);
    script.setAttribute('data-lazyload', lazyload);

    const lastScript = document.getElementById('wall-io');
    if (lastScript) {
      lastScript.remove();
    }

    const container = document.getElementById('walls-io-container');
    container.appendChild(script);
  }, []);

  return (
    <div id="walls-io-container">
      {/* walls io */}
    </div>
  );
};

export default Walls;
